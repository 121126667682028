import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {

  buttonTitle: string = 'contact.button';
  contactFormGroup: UntypedFormGroup;
  send: boolean = false;
  
  constructor(private _formBuilder: UntypedFormBuilder,
    private readonly streappService: StreappEventsService,
    private readonly authService: AuthService) { }

  ngOnInit(): void {
    const name: string = this.authService.userName;
    const email: string = this.authService.email;

    this.contactFormGroup = this._formBuilder.group({
      name: [name || '', [Validators.required, Validators.maxLength(150)]],
      email: [email || '', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.maxLength(1000)]],
    });

    if (!!name) {
      this.contactFormGroup.get("name").disable();
    }

    if (!!email) {
      this.contactFormGroup.get("email").disable();
    }
  }

  sendContact() {
    if(this.send) {
      this.send = false;
      return;
    }
    
    if(this.contactFormGroup.valid) {
      var contactData = {
        'Name': this.contactFormGroup.get("name").value,
        'Email': this.contactFormGroup.get("email").value,
        'Message': this.contactFormGroup.get("message").value,
      };
      
      this.streappService.sendContact(JSON.stringify(contactData));
      this.send = true;

      this.clearForm();
    }
  }

  getFabStyle(): string {
    if(this.contactFormGroup.valid) {
      return 'active group';
    } else {
      return 'disabled group';
    }
  }
  
  clearForm() {
    //reset form
    this.contactFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(150)]],
      email: ['', [Validators.required, Validators.email]],
      message: ['', [Validators.required, Validators.maxLength(1000)]],
    });
  }
  

}
