import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelpBoxService } from 'src/app/services/help-box.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent implements OnInit {

  loggedIn: boolean;

  constructor(private readonly authService: AuthService,
    private readonly helpBox: HelpBoxService,
    private readonly translate: TranslateService) { }

  ngOnInit(): void {
    this.loggedIn = this.authService.isAuthenticated();
  }

  dialogOpen() {
    this.translate.get('help.new-event.disabled').subscribe(text => {
      this.helpBox.openBox(text);
    })    
  }
}
