<feedback></feedback>
<div class="desktop-width-limiter page-padding page-about-us">
  <mat-toolbar>
    <button
      mat-button
      class="menu-item active"
      title="{{ 'about-us.hover_title' | translate }}"
    >
      <h1>{{ "about-us.title" | translate }}</h1>
    </button>
    <button
      mat-button
      class="menu-item d-none d-md-block"
      routerLink="/contact"
      title="{{ 'contact.hover_title' | translate }}"
    >
      <h1>{{ "contact.title" | translate }}</h1>
    </button>
    <button
      mat-button
      class="menu-item"
      routerLink="/terms"
      title="{{ 'menu.menu_items.terms' | translate }}"
    >
      <h1>{{ "menu.menu_items.terms" | translate }}</h1>
    </button>
  </mat-toolbar>
  <mat-card class="widget">
    <p>
      Leuk dat je nieuwsgierig bent wie wij zijn en naar het platform wat wij
      aan het ontwikkelen zijn. Bezoek
      <a href="https://streapp.nl" target="_blank">StreApp.nl</a> voor meer
      informatie over:
    </p>
    <ul class="dashed">
      <li>Wie wij zijn.</li>
      <li>De redenen en visie om StreApp te ontwikkelen.</li>
      <li>Onze kernwaarden en Reporters.</li>
      <li>Hoe je met ons kunt samenwerken.</li>
    </ul>
  </mat-card>
</div>
