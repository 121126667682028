import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { TermsComponent } from './terms/terms.component';

@NgModule({
  declarations: [AboutUsComponent, ContactComponent, TermsComponent],
  imports: [
    CommonModule,
    ComponentsModule
  ]
})
export class InfoModule { }
