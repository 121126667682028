<feedback></feedback>
<div class="fab-buttons">
  <fab
    *ngIf="contactFormGroup.dirty"
    [title]="'about-us.cancel'"
    [style]="'group no-icon'"
    (click)="clearForm()"
  ></fab>
  <fab
    [title]="'contact.button_send'"
    [icon]="'email'"
    [style]="getFabStyle()"
    (click)="sendContact()"
  ></fab>
</div>
<div class="desktop-width-limiter page-padding page-contact">
  <mat-toolbar>
    <button
      mat-button
      class="menu-item"
      routerLink="/over-ons"
      title="{{ 'about-us.hover_title' | translate }}"
    >
      <h1>{{ "about-us.title" | translate }}</h1>
    </button>
    <button
      mat-button
      class="menu-item active d-none d-md-block"
      title="{{ 'contact.hover_title' | translate }}"
    >
      <h1>{{ "contact.title" | translate }}</h1>
    </button>
    <button
      mat-button
      class="menu-item"
      routerLink="/terms"
      title="{{ 'menu.menu_items.terms' | translate }}"
    >
      <h1>{{ "menu.menu_items.terms" | translate }}</h1>
    </button>
  </mat-toolbar>
  <mat-card class="widget">
    <form *ngIf="!send" [formGroup]="contactFormGroup">
      <div class="step2">
        <mat-form-field appearance="outline" class="size-s">
          <mat-label>{{ "contact.name_label" | translate }}</mat-label>
          <input
            #name
            matInput
            formControlName="name"
            [placeholder]="'contact.name_placeholder' | translate"
            required
            maxlength="150"
          />
          <mat-hint align="end">{{ name.value?.length || 0 }} / 150</mat-hint>
          <mat-error>{{ "contact.name_validation" | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="size-s">
          <mat-label>{{ "contact.email_label" | translate }}</mat-label>
          <input
            #email
            matInput
            formControlName="email"
            [placeholder]="'contact.email_placeholder' | translate"
            required
          />
          <mat-hint align="end">{{ email.value?.length || 0 }} / 150</mat-hint>
          <mat-error>{{ "contact.email_validation" | translate }}</mat-error>
        </mat-form-field>
        {{ disableUserFields }}
        <mat-form-field appearance="outline" class="size-m">
          <mat-label>{{ "contact.message_label" | translate }}</mat-label>
          <textarea
            #message
            required
            maxlength="500"
            autosize
            rows="3"
            minRows="3"
            maxRows="10"
            formControlName="message"
            matInput
            [placeholder]="'contact.message_placeholder' | translate"
          ></textarea>
          <mat-hint align="end"
            >{{ message.value?.length || 0 }} / 500</mat-hint
          >
          <mat-error>{{ "contact.message_validation" | translate }}</mat-error>
        </mat-form-field>

        <div class="button-actions"></div>
      </div>
    </form>
    <div *ngIf="send">
      <h2>{{ "contact.email_send_title" | translate }}</h2>
      <span>{{ "contact.email_send" | translate }}</span>
    </div>
  </mat-card>
</div>
