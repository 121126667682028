import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { HelpBoxService } from 'src/app/services/help-box.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  loggedIn: boolean;

  constructor(private readonly authService: AuthService,
    private readonly helpBox: HelpBoxService,
    private readonly translate: TranslateService) { }

  ngOnInit(): void {
    this.loggedIn = this.authService.isAuthenticated();
  }

  dialogOpen() {
    this.translate.get('help.new-event.disabled').subscribe(text => {
      this.helpBox.openBox(text);
    });
  }
}
