import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { MaterialModule } from '../material.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleTextDialogComponent } from './simple-text-dialog/simple-text-dialog.component';
import { FabComponent } from './fab/fab.component';
import { PipesModule } from '../pipes/pipes.module';
import { OverviewCardComponent } from './overview-card/overview-card.component';
import { ProfileDialogComponent } from './profile-dialog/profile-dialog.component';
import { ScrollUpComponent } from './scroll-up/scroll-up.component';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { AdCardComponent } from './ad-card/ad-card.component';
import { PromoCardComponent } from './promo-card/promo-card.component';
import { StreAppDialogComponent } from './streapp-dialog/streapp-dialog.component';
import { PromoDialogComponent } from './promo-dialog/promo-dialog.component';
import { AdDialogComponent } from './ad-dialog/ad-dialog.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { ImageBlockComponent } from './image-block/image-block.component';
import { LocationSettingsComponent } from './location-settings/location-settings.component';
import { LocationSearchFieldComponent } from './location-search-field/location-search-field.component';
import { RequiredLocationDialogComponent } from './location-settings/required-location-dialog/required-location-dialog.component';
import { ReportingButtonComponent } from './reporting-button/reporting-button.component';
import { ReportingDialogComponent } from './reporting-button/reporting-dialog/reporting-dialog.component';
import { MobileChoiceDialogComponent } from './mobile-choice-dialog/mobile-choice-dialog.component';
import { P2000CardComponent } from './p2000-card/p2000-card.component';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { OverviewCardAlternateVersionComponent } from './overview-card-alternate-version/overview-card-alternate-version.component';
import { AdCardV2Component } from './ad-card-v2/ad-card-v2.component';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?hash={{POST_BUILD_ENTERS_HASH_HERE}}');
}

@NgModule({
  declarations: [
    SimpleTextDialogComponent,
    FabComponent,
    ScrollUpComponent,
    FeedbackComponent,
    OverviewCardComponent,
    AdCardComponent,
    PromoCardComponent,
    PromoDialogComponent,
    AdDialogComponent,
    ProfileDialogComponent,
    FeedbackDialogComponent,
    WelcomeDialogComponent,
    StreAppDialogComponent,
    ImageCarouselComponent,
    ImageBlockComponent,
    LocationSettingsComponent,
    LocationSearchFieldComponent,
    RequiredLocationDialogComponent,
    ReportingButtonComponent,
    ReportingDialogComponent,
    MobileChoiceDialogComponent,
    P2000CardComponent,
    DownloadButtonComponent,
    OverviewCardAlternateVersionComponent,
    AdCardV2Component
    ],
  imports: [
    CommonModule,
    MaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    SimpleTextDialogComponent,
    FabComponent,
    ScrollUpComponent,
    FeedbackComponent,
    OverviewCardComponent,
    AdCardComponent,
    AdCardV2Component,
    PromoCardComponent,
    ImageCarouselComponent,
    ImageBlockComponent,
    LocationSettingsComponent,
    LocationSearchFieldComponent,
    RequiredLocationDialogComponent,
    ReportingButtonComponent,
    MobileChoiceDialogComponent,
    P2000CardComponent,
    DownloadButtonComponent,
    OverviewCardAlternateVersionComponent
  ],
  providers: [],
  bootstrap: []
})
export class ComponentsModule {
}
