<feedback></feedback>
<div class="desktop-width-limiter page-padding page-terms">
  <mat-toolbar>
    <button
      mat-button
      class="menu-item"
      routerLink="/over-ons"
      [title]="'about-us.hover_title' | translate"
    >
      <h1>{{ "about-us.title" | translate }}</h1>
    </button>
    <button
      mat-button
      class="menu-item d-none d-md-block"
      routerLink="/contact"
      [title]="'contact.hover_title' | translate"
    >
      <h1>{{ "contact.title" | translate }}</h1>
    </button>
    <button
      mat-button
      class="menu-item active"
      routerLink="/terms"
      [title]="'menu.menu_items.terms' | translate"
    >
      <h1>{{ "menu.menu_items.terms" | translate }}</h1>
    </button>
  </mat-toolbar>
  <mat-card class="widget">
    <p>
      Hieronder zie je de voorwaarden in het kort, mocht je de volledige
      voorwaarden willen lezen dan kan dat
      <a href="/StreApp-Algemene-voorwaarden.pdf" target="_blank"
        >via deze link</a
      >. <br /><br />
      - Hou het netjes. (Geen seksistische, discriminerende of anders vervelende
      berichten)<br />
      - We delen jouw persoonlijke data niet met derden.<br />
      - Jij krijgt geld, als jou StreApp wordt verkocht!<br />
      - Upload alleen content waarvan jij de rechten bezit.<br />
    </p>
  </mat-card>
</div>
